.why-us {
  font-size: 4vw;
  color: #f7af34;
}
.cont {
  padding: 30px;
  font-family: "Work Sans", sans-serif;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 2%;
}
.feature {
  height: 20vh;
}
.flip-card-front > h1 {
  font-weight: bolder;
  text-align: center;
  font-size: 2vw;
}
.no-wrap {
  white-space: nowrap;
}
.stack-col {
  padding-bottom: 15px;
  padding-right: 0px;
  color: black;
  text-align: center;
}
.rowst {
  display: flex;
  justify-content: center;
  padding: 30px;
}

.flip-card {
  background-color: transparent;
  width: 21.5vw;
  height: 35vh;

  perspective: 1000px;
}
.one-stop {
  font-size: 31px;
  font-weight: bolder;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;

  text-align: center;
  border-radius: 15px;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

/* .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
   */

.flip-card-front,
.flip-card-back {
  color: wheat;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 15px;
}

.flip-card-front {
  text-align: center;
  margin: auto;
  background-color: #000231;

  padding: 30px !important;
  color: #f7af34;
  font-weight: bold;
  transition: all 1s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flip-card-front:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.flip-card-back {
  padding: 30px;
  background-color: #f7af34;
  color: #01021d;

  transform: rotateY(180deg);
}
/* @media only screen and (max-width: 820px){
    .flip-card-front>h1{
        font-weight: bolder;
        text-align: center;
        font-size: 28px;
        
    }
} */

@media only screen and (max-width: 576px) {
  .feature {
    height: 10vh;
  }
  .flip-card-front > h1 {
    margin-top: 10px;
    font-size: 3vw;
  }
  .flip-card {
    width: 38vw;
    height: 20vh;
  }
  .why-us {
    font-size: 7vw;
  }
}

@media only screen and (min-width: 577px) and (max-width: 992px) {
  .flip-card {
    width: 36.5vw;
    height: 30vh;
  }
  .why-us {
    font-size: 6vw;
  }
}
