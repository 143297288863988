.App {
  text-align: center;
}
.About {
  font-family: "Work Sans", sans-serif;
}
.subscribe-content {
  margin-top: 80px;
  padding: 30px;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
  text-align: center;
}

.resubscribe-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.whatsapp {
  width: 70px;
  height: 70px;
  z-index: 9999;
  position: fixed;
  right: 25px;
  bottom: 100px;
  opacity: 0.5;
}

.whatsapp:hover {
  opacity: 1;
}

.social-btn {
  border: 1px solid black;
  padding: 5px;
  margin: 8px;
  width: 40%;
}
.social-btn span {
  color: #000;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0px 10px;
}

@media only screen and (max-width: 800px) {
  .resubscribe-content {
    display: block;
  }
  .social-btn {
    border: 0px;
  }
  .social-btn span {
    display: none;
  }
}
