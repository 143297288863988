.media-content {
  background-color: #000231;
  height: 700px;
  font-family: "Work Sans", sans-serif;
}

.media-header {
  color: white;
  padding-top: 3%;
}

.helper-text {
  color: white;
  font-weight: 500;
}
.card-style {
  height: 400px;
  width: 400px;
  text-align: center;
  background-color: #000231;
  background-size: cover;
  background-position: inherit;
  background-repeat: no-repeat;
  color: #fff;
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
}
/*Pen code from this point on*/
.btn-social {
  clear: both;
  white-space: nowrap;
  font-size: 0.8em;
  display: inline-block;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.35);
  margin: 2px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  overflow: hidden;
}

.btn-social:hover {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.45);
}

.btn-social:focus {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.4);
}

.btn-social > span,
.btn-icon > i {
  float: left;
  padding: 13px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  line-height: 1em;
}

.btn-social > span {
  padding: 14px 18px 16px;
  white-space: nowrap;
  color: #fff;
  background: #b8b8b8;
}

.btn-social:focus > span {
  background: #9a9a9a;
}

.btn-icon > i {
  border-radius: 5px 0 0 5px;
  position: relative;
  width: 40px;
  text-align: center;
  font-size: 1.25em;
  color: #fff;
  background: #212121;
}

.btn-icon > i:after {
  content: "";
  border: 8px solid;
  border-color: transparent transparent transparent #222;
  position: absolute;
  top: 13px;
  right: -15px;
}

.btn-icon:hover > i,
.btn-icon:focus > i {
  color: #fff;
}

.btn-icon > span {
  border-radius: 0 5px 5px 0;
}

/*Facebook*/
.btn-facebook:hover > i,
.btn-facebook:focus > i {
  color: #3b5998;
}

.btn-facebook > span {
  background: #3b5998;
}

/*Twitter*/
.btn-twitter:hover > i,
.btn-twitter:focus > i {
  color: #55acee;
}

.btn-twitter > span {
  background: #55acee;
}

/*Google*/
.btn-instagram:hover > i,
.btn-instagram:focus > i {
  color: #e4405f;
}

.btn-instagram > span {
  background-color: #fb3958;
}

/*LinkedIn*/
.btn-linkedin:hover > i,
.btn-linkedin:focus > i {
  color: #007bb6;
}

.btn-linkedin > span {
  background: #007bb6;
}

.card-container {
  position: relative;
  width: 100%;
  height: 70%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: middle;
}

.media-btns {
  display: flex;
  justify-content: center;
}
.media-btn {
  width: 162px;
  display: flex;
}

@media screen and (max-width: 767px) {
  .media-content {
    height: 750px;
  }
  .media-btns {
    display: block;
  }
  .card-style {
    height: 300px;
    width: 300px;
  }
  .card-container {
    margin-top: 5%;
    margin-bottom: 5%;
    height: 50%;
  }
  .social-container {
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 15%;
  }
}
