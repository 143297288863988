.visionRow{
    margin-top: 20px;
}

.valuesRow{
    margin-top: 30px;
}




.Mission{
    margin-top: 10px;
    
}

.mission{
    margin: auto;
    
    
}
.mission-img{
    height: 300px;
    
    
}
.missionContent{
    padding: 80px;
   
    text-align: left;
}
.missionContent>h1{
    font-weight: 700;
  color: #F1AB32;
}
.missionContent>p{
    padding-top: 10px;
    font-size: 20px;
    font-weight: 500 ;
  color: #000231 ;
  text-align: left;
}

/* vision */
.vison{
    
    margin: auto;
}
.vision-img{
    height: 250px;
    
    
    
    
    
}

.visionContent{
    padding: 80px;
   
    text-align: left;
}

.visionContent>h1{
    font-weight: 700;
  color: #F1AB32;
}
.visionContent>p{
    padding-top: 10px;
    font-size: 20px;
    font-weight: 500 ;
  color: #000231 ;
  text-align: left;
}

/* values */
.values{
   
    margin: auto;
}
.values-img{
 height: 250px;
}

/* media query */

@media only screen and (max-width: 450px){
    .mission-img{
        height: 200px;
         
        
    }

    .vision-img{
        height: 200px;
        
        
        
        
        
    }
    .valuesRow{
        margin-top: 100px;
    }
    .values-img{

        height: 160px;
       }
}