.body {
  font-family: Manrope, sans-seri;
}

.heroDiv {
  background-color: #000231;
  padding: 50px;
}

.button-link,
.button-link:hover {
  color: #fdb73d;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-top: 20px;
  display: block;
  margin-left: 80px;
  font-weight: 600;
  font-size: 1.5vw;
  cursor: pointer;
}

.arrow-hero {
  transition: all 0.5s ease;
  color: #fff;
  font-size: 1.5vw;
  cursor: pointer;
}
.button-link:hover .arrow-hero {
  padding-left: 15px;
}
.img-1 {
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1)),
    url("../assets/c1.jpg");
}
.img-2 {
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1)),
    url("../assets/c2.jpg");
}
.img-3 {
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1)),
    url("../assets/c3.jpg");
}
.carousel-imgs-block {
  background-color: #000231;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: myfirst 2s;
  /* background-color: slategrey;
  background-blend-mode: multiply; */
}
/* Chrome, Safari, Opera */
@-webkit-keyframes myfirst {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

.carousel-content {
  margin-top: 30px;
  margin-left: 20px;
}

/* Standard syntax */
@keyframes myfirst {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

.carousel-caption {
  position: absolute;
  bottom: 20px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  top: 15%;
  bottom: auto;
  width: 100%;
  max-width: 1920px;
  left: 2%;
  right: 5%;
  text-align: left;
}
.hero-slider-heading {
  text-align: left;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.hero-slider-title {
  text-align: left;
  font-size: 6vw;
  line-height: 0.95;
  font-weight: 600;
}
.two {
  color: #fdb73d;
  margin-left: 75px;
}

.hero-slider-description {
  max-width: 320px;
  margin-top: 20px;
  font-size: 20px;
  margin-left: 80px;
}

.carousel-imgs-block img {
  width: 277px !important;
}

.welcome-col > p {
  color: white;
  font-weight: 700;
  margin-top: -10px;
  font-size: 18px;
}

.heroRow {
  margin-top: 30px;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.phone {
  height: 600px;
  animation-name: phoneAnim;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
@keyframes phoneAnim {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
.welcome-col {
  color: black;
  text-align: left;
}
.welcome {
  font-size: 60px;
  font-weight: 700;
  color: #fdb73d;
}
.welcome-im {
  font-size: 70px;
  font-weight: 700;
  color: #fdb73d;
  margin-top: -30px;
}
.download {
  border-width: 2px;
  border-color: #fdb73d;
  background-color: transparent;
  color: #fdb73d;
  font-size: 20px;
  font-weight: 700;
  text-align: center;

  border-radius: 10px;
}
.download:hover {
  background-color: #fdb73d;
  border-color: #fdb73d;
  color: #000231;
}
.download:focus {
  background-color: #fdb73d;
  border-color: #fdb73d;
  color: #000231;
}

@media only screen and (max-width: 1200px) {
  .welcome {
    font-size: 60px;
    font-weight: 700;
    color: #fdb73d;
  }
  .welcome-im {
    font-size: 60px;
    font-weight: 700;
    color: #fdb73d;
    margin-top: -20px;
  }
  .phone {
    height: 500px;
  }
}

@media only screen and (max-width: 991px) {
  .heroDiv {
    background-color: #000231;
    height: 150vh;
  }
  .heroRow {
    margin-top: 90px;
  }
  .welcome {
    font-size: 60px;
    font-weight: 700;
    color: #fdb73d;
    text-align: center;
  }
  .welcome-im {
    font-size: 60px;
    font-weight: 700;
    color: #fdb73d;
    margin-top: -20px;
    text-align: center;
  }
  .welcome-col > p {
    color: white;
    font-weight: 700;
    margin-top: -20px;
    font-size: 24px;
    text-align: center;
  }
  .button {
    display: flex;
    justify-content: center;
  }

  .download {
    background-color: #000231;
    font-size: 20px;
    font-weight: 700;
    border-radius: 20px;
  }
}
@media only screen and (max-width: 820px) {
  .heroDiv {
    background-color: #000231;
    height: 150vh;
  }
  .heroRow {
    margin-top: 90px;
  }
  .welcome {
    font-size: 30px;
    font-weight: 700;
    color: #fdb73d;
    text-align: center;
  }
  .welcome-im {
    font-size: 40px;
    font-weight: 700;
    color: #fdb73d;
    margin-top: -20px;
    text-align: center;
  }
  .welcome-col > p {
    color: white;
    font-weight: 700;
    margin-top: -10px;
    font-size: 14px;
    text-align: center;
  }
  .download {
    background-color: #000231;
    font-size: 20px;
    font-weight: 700;
    border-radius: 20px;
  }
  .phone {
    height: 300px;
  }
}
@media only screen and (max-width: 380px) {
  .heroDiv {
    background-color: #000231;
    height: 100vh;
  }
}
@media only screen and (max-width: 560px) {
  .hero-slider-heading {
    font-size: 4vw;
  }
  .hero-slider-title {
    font-size: 7vw;
  }
  .hero-slider-description {
    margin-left: 30px;
    font-size: 4.5vw;
  }
  .two {
    margin-left: 30px;
  }
  .button-link,
  .button-link:hover {
    margin-left: 30px;
    font-size: 4.5vw;
  }
  .arrow-hero {
    font-size: 4.5vw;
  }
  .carousel-content {
    margin-top: 0px;
    margin-left: 0px;
  }
}
