.downDiv {
  background-color: #000231;
  padding: 70px;
}
.storeCol > h1 {
  font-weight: 700;
  color: white;
  font-family: "Work Sans", sans-serif;
}
.downApp {
  width: initial !important;
  height: 500px;
}

.ps-icon {
  width: initial !important;
  height: 20%;
  padding: 6px;
}
.as-icon {
  width: initial !important;
  height: 22%;
  padding: 6px;
}
.storeCol {
  padding-top: 40px;
}
@media only screen and (max-width: 820px) {
  .downApp {
    height: 300px;
  }
}

@media only screen and (max-width: 991px) {
  .downDiv {
    padding: 0px;
  }
}
