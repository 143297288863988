.contact-div {
  background-color: antiquewhite;
  padding: 10px;
}
.content-div {
  width: 50%;
  margin: auto;
  padding-bottom: 3%;
}
.submit-arrow {
  color: white !important;
}
.contact-col {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.social-media-icons {
  display: flex;
  width: 60%;
  justify-content: space-evenly;
  margin: auto;
  align-items: center;
}

.form-fields-container {
  text-align: left;
  margin-left: 11%;
}
.form-fields-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.form-group {
  margin-bottom: 0.5rem !important;
}
.message-field {
  width: 66%;
}
.fields-1 {
  width: 32%;
}

.error-input {
  border: 1px solid red;
}

.submit-btn {
  height: 36px;
  width: 100%;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #000231;
}

.icons-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
}

.social-media-helper-text {
  width: 40%;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
}

.form-control::-webkit-input-placeholder {
  font-family: "Work Sans", Arial, Helvetica, sans-serif;
}

.form-control:-ms-input-placeholder {
  font-family: "Work Sans", Arial, Helvetica, sans-serif;
}

.form-control:-moz-placeholder {
  font-family: "Work Sans", Arial, Helvetica, sans-serif;
}

.form-control::-moz-placeholder {
  font-family: "Work Sans", Arial, Helvetica, sans-serif;
}

.twitter-icon,
.twitter-icon:hover {
  color: #55acee;
  font-size: 35px;
  cursor: pointer;
}
.facebook-icon,
.facebook-icon:hover {
  color: #3b5998;
  font-size: 35px;
  cursor: pointer;
}
.instagram-icon,
.instagram-icon:hover {
  color: #e4405f;
  font-size: 35px;
  cursor: pointer;
}
.contact-div > div h1 {
  font-weight: bolder;
  margin-top: 10px;
  text-align: center;
  line-height: 1;
  letter-spacing: -1px;
  font-family: "Work Sans", sans-serif;
}

/* .contact-row{
    
    padding: 100px;
} */

.contact-submit-btn {
  width: auto;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .content-div {
    width: 100%;
  }
  .social-media-icons {
    width: 100%;
  }
  .form-fields-container {
    margin-left: 0%;
    text-align: center;
  }
  .form-fields-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .fields-1,
  .message-field {
    width: 90%;
  }
  .contact-submit-btn {
    width: 50%;
    margin: auto;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1005px) {
  .content-div {
    width: 70%;
  }
}
