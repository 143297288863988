.section-header {
  text-align: center;
  margin: 25px 15px;
  font-size: 40px;
  line-height: 1;
  letter-spacing: -1px;
  font-family: "Work Sans", sans-serif;
  color: #f1ab32;
}

.gallery {
  width: 71%;
  height: 349px;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-bottom: 5%;
}
.margin-right {
  margin-right: 12px;
}
.modal-dialog {
  text-align: center;
}
.modal-content {
  display: inline-block;
}

.image-intro {
  width: 359px;
  height: 357px;
  margin-right: 12px;
}
.margin-top {
  margin-top: 12px;
}

.gallery__img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  display: block;
  opacity: 0;
  transition: all 2s ease;
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -ms-transition: all 2s ease;
  -o-transition: all 2s ease;
}
.fade-image {
  opacity: 1;
}

.gallery__item--1 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 9;
  margin-bottom: 0px;

  /** Alternative Syntax **/
  /* grid-column: 1 / span 2;  */
  /* grid-row: 1 / span 2; */
}

.gallery__item--2 {
  grid-column-start: 3;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 9;
  margin-bottom: 0px;

  /** Alternative Syntax **/
  /* grid-column: 3 / span 2;  */
  /* grid-row: 1 / span 2; */
}

.gallery__item--3 {
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 8;
  margin-bottom: 0px;

  /** Alternative Syntax **/
  /* grid-column: 5 / span 4;
  grid-row: 1 / span 5; */
}

.gallery__item--4 {
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 7;
  margin-bottom: 0px;

  /** Alternative Syntax **/
  /* grid-column: 1 / span 4;  */
  /* grid-row: 3 / span 3; */
}

.gallery__item--5 {
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 3;
  grid-row-end: 11;
  margin-bottom: 0px;

  /** Alternative Syntax **/
  /* grid-column: 1 / span 4; */
  /* grid-row: 6 / span 3; */
}

.gallery__item--6 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 9;
  grid-row-end: 11;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  background-color: #000231;

  /** Alternative Syntax **/
  /* grid-column: 5 / span 4; */
  /* grid-row: 6 / span 3; */
}
.modal-img {
  width: 500px;
  height: 500px;
}

.right-imgs-block {
  width: auto;
  display: block;
}

.gallery__item--6 img {
  width: 154px;
}

.img-block {
  display: flex;
  justify-content: space-between;
}

.gallery__item--7 {
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 9;
  grid-row-end: 11;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  background-color: #000231;

  /** Alternative Syntax **/
  /* grid-column: 5 / span 4; */
  /* grid-row: 6 / span 3; */
}

.gallery__item--7 img {
  width: 154px;
}

/* .gallery img:hover::after {
  transform: translateY(50%);
} */
.gallery img:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1300px) {
  .gallery {
    height: 100%;
    max-height: 100%;
  }
  .image-intro {
    width: 100%;
    height: 100%;
    margin-right: 0px;
  }
  .right-imgs-block {
    width: 156%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .gallery__img {
    width: 95%;
  }
  .margin-top {
    margin-top: 0px;
  }
  .margin-right {
    margin-right: 0px;
  }
  .img-block {
    justify-content: unset;
  }
}

@media only screen and (max-width: 500px) {
  .gallery {
    width: 95%;
  }
}

@media only screen and (max-width: 600px) {
  .modal-img {
    width: 100%;
    height: 100%;
  }
}
