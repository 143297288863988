.footer {
  background-color: #01021d;
  color: white;
}
.footer-content {
  max-width: 100%;
  padding: 0 15px;
  padding: 4rem 12% 2rem 12%;
}
.foot-it {
  padding: 50px;
}
.foot-col {
  display: flex;
  justify-content: center;
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: "Work Sans", sans-serif;
}

.social-icons {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  overflow: hidden;
}

.footer-link {
  display: block;
  color: #fff;
  font-size: 16px;
  margin-bottom: 5px;
  cursor: pointer;
}
.footer li {
  list-style-type: none;
}
.footer-links {
  padding: 0px;
  text-align: left;
  margin-top: 10%;
}

.footer-link:hover {
  color: #f1ab32 !important;
  text-decoration: none !important;
}

.linked-in:hover {
  color: #007bb6;
}
.email-submit-btn {
  height: 36px;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #01021d;
  background-color: #f1ab32;
  width: 45%;
  margin: auto;
  margin-top: 5%;
  font-weight: 500;
}

.top-btn {
  display: flex;
  align-items: center;
  margin-right: 1%;
  cursor: pointer;
}

.email-subscribe {
  width: 100%;
  border: 0 !important;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid white !important;
  color: white;
}
.form-helper-text {
  margin-top: 3%;
  margin-bottom: 3%;
  font-family: "Work Sans";
  font-weight: 500;
}
.footer-header {
  margin-bottom: 7%;
}
.email-subscribe:focus {
  border-bottom: 1px solid #f1ab32 !important;
}
.fb:hover {
  color: #3b5998;
}
.insta:hover {
  color: #e4405f;
}
.twitter:hover {
  color: #55acee;
}
.gmail:hover {
  color: #c71610;
}

.links-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.social-icons-list {
  float: left;
  margin: 10px 12px 10px 0;
}
.social-icon {
  background: #ffffff;
  min-width: 34px;
  min-height: 34px;
  display: inline-block;
  font-size: 18px;
  text-align: center;
  border-radius: 50%;
  padding-top: 4px;
  color: #1e1e1e;
}

.playstore {
  height: 180px;

  padding: 40px;
}

@media only screen and (max-width: 1000px) {
  .playstore {
    height: 150px;

    padding: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .footer-row {
    justify-content: space-evenly;
  }
}
@media only screen and (max-width: 400px) {
  .playstore {
    height: 130px;

    padding: 40px;
  }
}
