.contact-div-1 {
  background-color: #ffffff90;
  padding: 10px;
  width: 40%;
  margin-top: 5%;
}
.submit-arrow {
  color: white !important;
}
.error-input2 {
  border: 1px solid red;
}
.form-fields-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.form-group {
  margin-bottom: 0.5rem !important;
}
.message-field-2 {
  width: 100%;
}
.fields-2 {
  width: 48%;
}

.submit-btn-1 {
  height: 36px;
  width: 50%;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #000231;
}

.icons-container-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 30px 0px;
  margin-top: 70px;
  width: 100%;
}

.form-control::-webkit-input-placeholder {
  font-family: "Work Sans", Arial, Helvetica, sans-serif;
}

.form-control:-ms-input-placeholder {
  font-family: "Work Sans", Arial, Helvetica, sans-serif;
}

.form-control:-moz-placeholder {
  font-family: "Work Sans", Arial, Helvetica, sans-serif;
}

.form-control::-moz-placeholder {
  font-family: "Work Sans", Arial, Helvetica, sans-serif;
}

.linkedin-icon-1,
.linkedin-icon-1:hover {
  color: #55acee;
  font-size: 28px;
  cursor: pointer;
}
.facebook-icon-1,
.facebook-icon-1:hover {
  color: #3b5998;
  font-size: 28px;
  cursor: pointer;
}
.instagram-icon-1,
.instagram-icon-1:hover {
  color: #e4405f;
  font-size: 28px;
  cursor: pointer;
}
.contact-div-1 > div h1 {
  font-weight: bolder;
  margin-top: 10px;
  text-align: center;
  line-height: 1;
  letter-spacing: -1px;
  font-family: "Work Sans", sans-serif;
}

.icons-container-1 div {
  margin-bottom: 10px;
}
/* .contact-row{
    
    padding: 100px;
} */

.contact-submit-btn-1-1 {
  width: auto;
  margin: auto;
}

/* Steps styles */

.steps-header {
  width: 60%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.step-img {
  width: 450px;
  height: 450px;
}

.step-text {
  font-size: 4rem;
  margin-left: 5%;
  z-index: 1;
}

.step1-text {
  font-size: 4rem;
  margin-left: 15%;
  z-index: 1;
}

.step-container {
  display: flex;
  position: relative;
  align-items: center;
  margin: 50px 0px;
  padding: 0px 5%;
}

.steps-container {
  width: 100%;
  background-color: #fff;
  text-align: start;
}

.step-circle {
  position: absolute;
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
}

.step-note-text {
  font-size: 2rem;
}

.step-main-text {
  font-size: 7rem;
  font-weight: bolder;
  line-height: 1;
}

/* WhatWeDo styles */

.what-we-do-container {
  width: 100%;
  padding: 3% 7%;
  background-color: #0f1134;
  color: #fff;
  text-align: start;
}

.vissions-container {
  display: flex;
  flex-direction: row;
}

/* Countries styles */

.country-container {
  width: 100%;
  padding: 3% 7%;
  background-color: #ffefd7;
}

.countries-c {
  width: 70%;
  margin: auto;
}

.country-img {
  width: 80px;
  height: 70px;
  border-radius: 10px;
}

/* Pricing styles */

.pricing-container {
  width: 100%;
  padding: 3% 7%;
  background-color: #fff;
}

.price-cards-container {
  display: flex;
  justify-content: center;
}

/* TryOut styles */

.try-out-container {
  width: 100%;
  padding: 3% 7%;
  background-color: #0f1134;
  color: #fff;
  text-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-text {
  font-size: 3rem;
  font-weight: bolder;
}

@media only screen and (max-width: 768px) {
  .contact-div-1 {
    width: 100%;
    margin-top: 25%;
  }
  .form-fields-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .fields-2,
  .message-field-2 {
    width: 90%;
  }
  .submit-btn-1 {
    width: 70%;
    margin: auto;
  }
  .try-out-container {
    flex-direction: column;
    padding: 7% 3%;
  }
  .main-text {
    font-size: 2.2rem;
  }
  .pricing-container {
    padding: 7% 3%;
  }
  .price-cards-container {
    flex-direction: column;
    align-items: center;
  }
  .country-container {
    padding: 7% 3%;
  }
  .country-img {
    width: 55px;
    height: 45px;
    border-radius: 5px;
  }
  .what-we-do-container {
    padding: 7% 3%;
  }
  .vissions-container {
    flex-direction: column;
  }
  .countries-c {
    width: 100%;
  }
  .steps-header {
    width: 90%;
  }
  .step-main-text {
    font-size: 3rem;
  }
  .step-img {
    width: 150px;
    height: 150px;
  }
  .step-text {
    font-size: 1.5rem;
  }
  .step1-text {
    font-size: 1.5rem;
    margin-left: 5%;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1005px) {
  .contact-div-1 {
    width: 70%;
  }
  .steps-header {
    width: 80%;
  }
  .step-main-text {
    font-size: 5rem;
  }
  .step-img {
    width: 300px;
    height: 300px;
  }
  .step-text {
    font-size: 3rem;
  }
  .step1-text {
    font-size: 3rem;
  }
}
