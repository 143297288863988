.content-section.widget-section,
.content-section.n5_facts_block {
  padding-top: 70px;
  padding-bottom: 70px;
}

.content-section {
  padding-top: 50px;
  padding-bottom: 50px;
  color: #1e1e1e;
  background-color: #fff;
}
.newui-content-wrapper {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 72px;
  font-family: Manrope, sans-serif;
}

.widget-section-header {
  font-size: 18px;
  line-height: 1.7;
}
.title {
  display: flex;
  flex-direction: column;
  font-size: 5vw;
  line-height: 1;
  letter-spacing: -1px;
  font-family: ManRope, sans-serif;
  color: #1e1e1e;
}
.description {
  color: #9398a1;
  font-family: "Work Sans", sans-serif;
  padding: 0px;
}
.bottom-text {
  text-align: left;
  margin-left: 10%;
}
/* .bottom-text:hover {
  white-space: unset;
  overflow: visible;
} */

.newui-content-wrapper {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 72px;
  font-family: Manrope, sans-serif;
}
.cta-link {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}
.solution-link {
  font-weight: 700;
  color: #f7af34;
  letter-spacing: 3px;
  font-size: 18px;
  text-transform: uppercase;
  cursor: pointer;
}
.arrow-video {
  color: #1e1e1e;
  transition: all 0.5s ease;
  font-size: 20px;
  cursor: pointer;
  margin-right: 5px;
  padding-left: 10px;
}
.button-link:hover .arrow-video {
  margin-right: 0px;
  padding-left: 15px;
}
@media only screen and (max-width: 1456px) {
  .newui-content-wrapper {
    max-width: 100%;
    padding: 0 15px;
  }
}

@media only screen and (max-width: 1456px) {
  .newui-content-wrapper {
    max-width: 100%;
    padding: 0 15px;
  }
}

@media only screen and (max-width: 991px) {
  .title {
    justify-content: center;
    padding-left: 0px;
    font-size: 40px;
    text-indent: 0px;
  }
  .description {
    padding: 20px;
  }
}
