.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.navbar-background {
  background-color: #000231 !important;
  backdrop-filter: unset;
}

.navbar {
  font-family: "Work Sans", sans-serif;
  background-color: #000231;
  color: #f1ab32;
  transition: all 0.5s ease;
  flex-wrap: nowrap !important;
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .navbar {
    background-color: transparent;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }
}
.left-menu {
  display: flex;
  width: 100%;
  align-items: initial;
}

.logo {
  height: 46px;
  width: 46px;
}

.brand {
  font-weight: 700;
  font-size: 30px;
  margin-left: 9px;
  color: #f1ab32;
  transition: ease-in-out 0.2s;
}

a:hover {
  text-decoration: none !important;
}

/* .activeLink {
  background-color: #f1ab32;
  transition: 1s;
  border-radius: 15px;
  color: #000231;
  text-decoration: none !important;
} */

.navList {
  font-family: "Work Sans", sans-serif;
  width: 100%;
  transition: ease-in-out 0.2s;
}

.navItem {
  font-weight: 500;
  cursor: pointer;
  padding: 0px 20px 0px 20px;
}

.contact {
  padding: 0px 20px 0px 20px;

  border-radius: 15px;
  font-weight: 500;
}

.active {
  color: white;
  transition: ease-in-out 0.2s;
}
.loginButton,
.loginButton:hover {
  background: linear-gradient(
    77deg,
    rgba(252, 160, 5, 1) 0%,
    rgba(255, 177, 46, 1) 100%
  );
  border-radius: 10px;
  color: #000231 !important;
}

.nav-link:hover {
  color: #f1ab32;
  text-decoration: none !important;
}
.activeLink,
.activeLink:hover {
  color: #f1ab32;
  text-decoration: none !important;
}

.align-items {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .left-menu {
    flex-direction: column;
    align-items: center;
  }
  .align-items {
    display: block;
  }
  .contact-btn {
    width: 50%;
    margin: auto;
    margin-top: 5%;
  }
  .contact {
    padding: 0px;
  }
}
